export class Rule {
  name: string;
  name_arabic: string;
  irab: string;
  irab_char: string;
  explanation: string;


  constructor(name: string, name_arabic: string, irab: string, irab_char: string, explanation: string) {
    this.name = name;
    this.name_arabic = name_arabic;
    this.irab = irab;
    this.irab_char = irab_char;
    this.explanation = explanation;
  }
}

export class SentenceWord {
  word: string;
  rule: Rule;


  constructor(word: string, rule: Rule) {
    this.word = word;
    this.rule = rule;
  }
}

export class Sentence {
  words: Array<SentenceWord>;
  translation: string;

  constructor(words: Array<SentenceWord>, translation: string) {
    this.words = words;
    this.translation = translation;
  }
}

// ----------------------------------------------------------
// RULES
// ----------------------------------------------------------

export const ruleFiil = new Rule("fi'il", "fi'il", "mansoob", "َ", "fi'il explained")
export const ruleFail = new Rule("fa'il", "fa'il", "mansoob", "َ", "fa'il explained")
export const ruleMafulBih = new Rule("mafool bih", "mafool bih", "mansoob", "َ", "mafool bih explained")
export const ruleMafulFihi = new Rule("maf'ool fihi", "maf'ool bihi", "mansoob", "َ", "");
export const ruleMafulMutlaq = new Rule("maf'ool mutlaq", "maf'ool mutlaq", "mansoob", "َ", "");
export const ruleMafulLahu = new Rule("maf'ool lahu", "maf'ool lahu", "mansoob", "َ", "");

export const ruleHurfJar = new Rule("hurf al jarr", "hurf al jarr", "majroor", "ِ",  "");

export const ruleKhabrInna = new Rule("khabr inna", "khabr inna", "marfoo", "ُ", "");

export const rules = {
  marfoo: {
    ruleKhabrInna: ruleKhabrInna
  },
  mansoob: {
    ruleFiil: ruleFiil,
    ruleFail: ruleFail,
    ruleMafulBih: ruleMafulBih,
    ruleMafulFihi: ruleMafulFihi,
    ruleMafulMutlaq: ruleMafulMutlaq,
    ruleMafulLahu: ruleMafulLahu,
  },
  majroor: {
    ruleHurfJar:ruleHurfJar,
  },
}
