import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text, Heading,
  VStack, Flex,
  Grid, Button, Stack, HStack, Image,
  extendTheme
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import Logo from "./assets/wbicc-logo.png"
import RulesPage from './pages/rules/RulesPage'
import WordsPage from './pages/WordsPage'
import { Route, Routes, BrowserRouter, useNavigate} from "react-router-dom";

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
});

export const App = () => {

  const navigate = useNavigate();

  return (
      <ChakraProvider theme={theme}>
        <Box textAlign="center" fontSize="xl">
          {/*<Grid minH="100vh" p={3} alignContent={"flex-start"}>*/}
          <Flex direction={"column"} minH={["70vh"]} p={3}>
            <Flex justify={"space-between"} align={"center"} paddingBottom={"20px"}>
              <HStack align={"end"}>
                <Image src={Logo} height={30} width={30}/>
                <Heading
                  as={"h1"}
                  size={"lg"}
                  justifySelf="flex-start"
                  textDecoration={"underline 4px"}
              >
                nahw.io
              </Heading>
              </HStack>
              <HStack>
                <Button onClick={() => navigate("/rules")}>Rules</Button>
                <Button onClick={() => navigate("/")}>Words</Button>
                <ColorModeSwitcher justifySelf="flex-end" />
              </HStack>
            </Flex>
            <VStack spacing={8} flex={1}>
              <Routes>
                <Route path={"/"} element={<WordsPage />} />
                <Route path={"/rules"} element={<RulesPage />} />
              </Routes>
            </VStack>
          </Flex>
          <Stack bg={"gray.800"} minH={"30vh"} paddingTop={"20px"}>
            <Text color={"white"}>Built in affiliation with <a href={"wbicc.org.uk"}><u>Whitley Bay Islamic Center</u></a></Text>
          </Stack>
          {/*</Grid>*/}
        </Box>
      </ChakraProvider>
  )
}
