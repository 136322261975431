import * as React from 'react';
import {Stack, HStack, Heading, Text, Tag, Box, Select,
  Button, ButtonGroup, useRadio, useRadioGroup, RadioGroup, Radio,
  useToast, Alert, AlertDescription, Flex, Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
} from '@chakra-ui/react'
import {useEffect, useState} from "react";


function WordsPage() {
  const [word, setWord] = useState("أكل");
  const [wordForms, setWordForms] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast()


  useEffect(() => {
  }, []);

  function onInput(event) {
    setWord(event.target.value)
  }

  function onSearch() {
    setIsLoading(true)
    fetch(`https://whitleybayislamiccentre.org.uk/wp-json/nahw-io/v1/words/${word}`)
        .then(response => response.json())
        .then(data => setWordForms(data[0]))
        .catch(e =>toast({status: "error", description: e.message}))
        .finally(() => setIsLoading(false))
  }

  return (
      <Stack spacing={10} flex={1} width={"100%"}>
        <Heading>
          Words
        </Heading>
        <Stack spacing={4} paddingBottom={"15px"}>
          <Input lang={"ar"} type={"text"} defaultValue={word} onChange={onInput} />
        </Stack>
        {/*<Text>{JSON.stringify(wordForms)}</Text>*/}
        <HStack spacing={2} align={"center"} justify={"center"}>
          <Button variant="outline" colorScheme={"blue"} onClick={onSearch} isLoading={isLoading}>Submit</Button>
        </HStack>

        {wordForms &&
        <>
          <Stack spacing={6}>
            <Heading>Sigha</Heading>
            <HStack spacing={3} align={"start"} justify={"center"} wrap={"wrap"} flexDirection={"row-reverse"}>
              <SighaText text={`${wordForms?.past_singular_masc_third}`}/>
              <SighaText text={`${wordForms?.present_singular_masc_third}`}/>
              <SighaText text={`${wordForms?.masdar}`}/>
              <SighaText text={`فهو`} fillerText/>
              <SighaText text={`${wordForms?.faail}`}/>

              <SighaText text={`و`} fillerText/>
              <SighaText text={`${wordForms?.past_majhool_singular_masc_third}`}/>
              <SighaText text={`${wordForms?.present_majhool_singular_masc_third}`}/>
              <SighaText text={`فهو`} fillerText/>
              <SighaText text={`${wordForms?.mafool}`}/>

              <SighaText text={`الأمر منه`} fillerText/>
              <SighaText text={`${wordForms?.command_single_masc}`}/>
              <SighaText text={`و النهي عنه`} fillerText/>
              <SighaText text={`لا ${wordForms?.present_singular_masc_second}`}/>
            </HStack>
          </Stack>

          <Stack spacing={6}>
            <Heading>Forms</Heading>
            <Table variant="striped" size={"sm"}>
              {/*<TableCaption>Imperial to metric conversion factors</TableCaption>*/}
              <Thead>
                <Tr>
                  <Th textAlign={"center"}>
                    <Text fontSize={"lg"}>جمع</Text>
                    <Text>(Plu.)</Text>
                  </Th>
                  <Th textAlign={"center"}>
                    <Text fontSize={"lg"}>مثنّى</Text>
                    <Text>(Dual)</Text>
                  </Th>
                  <Th textAlign={"center"}>
                    <Text fontSize={"lg"}>مفرد</Text>
                    <Text>(Sing.)</Text>
                  </Th>
                  <Th textAlign={"center"}></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Th colspan={4} textAlign={"center"}>
                    <HStack align={"center"} justify={"center"}>
                      <Text>الماضي</Text>
                      <Text fontSize={"0.9em"}>(Past)</Text>
                    </HStack>
                  </Th>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_plural_masc_third}</Td>
                  <Td  textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_dual_masc_third}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_singular_masc_third}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}> مذكر غائب </Text>
                    <Text>(Third Person Male)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_plural_fem_third}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_dual_fem_third}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_singular_fem_third}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}> مؤنث غائب </Text>
                    <Text>(Third Person Female)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_plural_masc_second}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_dual_masc_second}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_singular_masc_second}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}>مذكر حاضر </Text>
                    <Text>(Second Person Male)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_plural_fem_second}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_dual_fem_second}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_singular_fem_second}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}>مؤنث حاضر </Text>
                    <Text>(Second Person Female)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"} colspan={2}>{wordForms?.past_plural_first}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.past_singular_first}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}>متكلّم</Text>
                    <Text>(First Person)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Th colspan={4} textAlign={"center"} >
                    <HStack align={"center"} justify={"center"}>
                      <Text>المضارع</Text>
                      <Text fontSize={"0.9em"}>(Present)</Text>
                    </HStack>
                  </Th>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_plural_masc_third}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_dual_masc_third}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_singular_masc_third}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}> مذكر غائب </Text>
                    <Text>(Third Person Male)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_plural_fem_third}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_dual_fem_third}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_singular_fem_third}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}> مؤنث غائب </Text>
                    <Text>(Third Person Female)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_plural_masc_second}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_dual_masc_second}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_singular_masc_second}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}>مذكر حاضر </Text>
                    <Text>(Second Person Male)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_plural_fem_second}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_dual_fem_second}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_singular_fem_second}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}>مؤنث حاضر </Text>
                    <Text>(Second Person Female)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"} colspan={2}>{wordForms?.present_plural_first}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.present_singular_first}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}>متكلّم</Text>
                    <Text>(First Person)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Th colspan={4} textAlign={"center"} >
                    <HStack align={"center"} justify={"center"}>
                      <Text>الأمر</Text>
                      <Text fontSize={"0.9em"}>(Command)</Text>
                    </HStack>
                  </Th>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.command_plural_masc}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.command_dual_masc}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.command_single_masc}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}>مذكر حاضر</Text>
                    <Text>(Second Person Male)</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.command_plural_fem}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.command_dual_fem}</Td>
                  <Td textAlign={"center"} fontSize={"2xl"}>{wordForms?.command_single_fem}</Td>
                  <Td textAlign={"center"} fontSize={"xs"}>
                    <Text fontSize={"lg"}>مؤنث حاضر</Text>
                    <Text>(Second Person Female)</Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Stack>
        </>
        }
      </Stack>
  )
}

function SighaText(props: {text: string, fillerText: boolean}) {
  return <Text fontSize={"3xl"} marginLeft={"10px"} color={props.fillerText ? 'gray.500' : 'none'}>{props.text}</Text>
}


export default WordsPage;
