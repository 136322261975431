import * as React from 'react';
import {Stack, HStack, Heading, Text, Tag, Box, Select,
  Button, ButtonGroup, useRadio, useRadioGroup, RadioGroup, Radio,
  useToast, Alert, AlertDescription, Flex
} from '@chakra-ui/react'
import {
  Rule,
  ruleFail,
  ruleFiil, ruleHurfJar,
  ruleKhabrInna,
  ruleMafulBih,
  ruleMafulFihi, ruleMafulLahu, ruleMafulMutlaq,
  Sentence,
  SentenceWord,
} from "../../models";
import {useEffect, useState} from "react";
import { WordTag } from '../../components';

const data = [
  new Sentence([
    new SentenceWord("خرج", ruleFiil),
    new SentenceWord("الرجل", ruleFail),
    new SentenceWord("المسجد", ruleMafulBih),
  ], "The man left the mosque")
]

const rules = {
  marfoo: [
    ruleKhabrInna,
    ruleFiil,
  ],
  mansoob: [
      ruleFail,
      ruleMafulBih,
      ruleMafulFihi,
      ruleMafulMutlaq,
      ruleMafulLahu,
  ],
  majroor: [
    ruleHurfJar,
  ],
}

function RulesPage(props : {sentenceWithCorrectRules: Sentence}) {
  const [sentenceWithCorrectRules, setSentenceWithCorrectRules] = useState<Sentence>();
  // const {sentenceWithCorrectRules} = {...props}
  const [sentence, setSentence] = useState<Sentence>({});
  const [selectedWordIndex, setSelectedWordIndex] = useState<number>();
  const [word, setWord] = useState<SentenceWord>();
  const [selectedIrab, setSelectedIrab] = useState<number>();
  const [wordErrors, setWordErrors] = useState<string>()

  const toast = useToast()


  useEffect(() => {
    setSentenceWithCorrectRules(data[0]);
  }, []);

  useEffect(() => {
    if (selectedWordIndex !== undefined && selectedWordIndex >= 0) {
      setWord(sentence?.words?.length > 0 ? sentence.words[selectedWordIndex] : null);
    } else {
      setWord(null)
    }
  }, [selectedWordIndex]);

  useEffect(() => {
    if (word?.rule?.irab) {
      setSelectedIrab(word.rule.irab)
    } else {
      setSelectedIrab("")
    }
  }, [word]);

  useEffect(() => {
    copySentenceAndClearRules()
  }, [sentenceWithCorrectRules]);

  function copySentenceAndClearRules() {
    if (sentenceWithCorrectRules) {
      const sentenceCopy = {...sentenceWithCorrectRules}
      const numberOfWords = sentenceCopy?.words?.length || 0
      const newWords = []
      for (let i=0; i<numberOfWords; i++) {
        const word = {...sentenceCopy.words[i]}
        if (word) {
          word.rule = new Rule("", null, null, null, null)
        }
        newWords.push(word)
      }
      sentenceCopy.words = newWords
      console.log("sentenceCopy", sentenceCopy)
      setSentence(sentenceCopy);
    }
  }

  function onWordSelected(word: SentenceWord, index: number) {
    setSelectedWordIndex(index)
  }

  function onRuleSelected(rule: Rule) {
    console.log("Updating rule on sentence", rule)
    const s = {...sentence}
    if (s && selectedWordIndex !== undefined && selectedWordIndex >= 0) {
      console.log("Updating rule on sentence")
      // const sentence = {...s}
      const words = [...s.words]
      words[selectedWordIndex].rule = rule
      s.words = words
      setSentence(s)
    }
  }

  function verifyWordRules() {
    clearErrorsAndSelections()
    const correctWords = sentenceWithCorrectRules?.words
    const inputWords = sentence?.words

    if (correctWords?.length !== inputWords?.length) {
      toast({
        title: 'Error',
        description: 'Number words do not match',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    } else {
      const errors = new Array(correctWords.length)
      console.log("correctWords.length", correctWords.length)
      for (let i = 0; i < correctWords.length; i++) {
        const correctWord = correctWords[i]
        const inputWord = inputWords[i]
        console.log("correctWord", correctWord, "inputWord", inputWord)
        if (correctWord.rule.name !== inputWord.rule.name) {
          console.log("Correct rule is " + correctWord.rule.name)
          errors[i] = "Correct rule is " + correctWord.rule.name
        } else {
          console.log("Rule correct")

        }

      }
      // do something
      setWordErrors(errors)
    }

  }

  function clearAll() {
    clearErrorsAndSelections()
    copySentenceAndClearRules()
  }

  function clearErrorsAndSelections() {
    setSelectedWordIndex(null)
    setSelectedIrab("")
    setWordErrors([])
  }

  return (
      <Stack spacing={4} flex={1} width={"100%"}>
        <Heading>
          Rules
        </Heading>
        <Stack spacing={4} flex={1} paddingBottom={"15px"}>
          <HStack
              alignSelf={"center"}
              align={"flex-start"}
          >
            {sentence?.words?.map((w, i) =>
                <Stack>
                  <WordTag
                      sentenceWord={w}
                      selected={selectedWordIndex === i}
                      onClick={() => onWordSelected(w, i)}
                  />
                  {wordErrors && wordErrors[i] && <Tag colorScheme={"red"}>{wordErrors[i]}</Tag>}

                </Stack>
            ).reverse()}
          </HStack>
          {word &&
              <Stack spacing={4}>
                <IrabRadio sentenceWord={word} onIrabSelect={(value) => setSelectedIrab(value)}/>
                <IrabReasonRadio sentenceWord={word} irab={selectedIrab} onReasonSelect={onRuleSelected}/>
              </Stack>
          }
        </Stack>
        <HStack spacing={2} align={"center"} justify={"center"}>
          <Button colorScheme={"blue"} onClick={verifyWordRules}>Validate Answer</Button>
          <Button variant="outline" colorScheme={"blue"} onClick={clearAll}>Clear</Button>
        </HStack>
      </Stack>
  )
}

function IrabRadio(props : {sentenceWord: SentenceWord, onIrabSelect: (irab: string) => void}) {
  const {sentenceWord, onIrabSelect=()=>null} = {...props}

  const ruleNames = Object.keys(rules);
  const rulesRadioGroup = useRadioGroup({
    name: "irab",
    onChange: (value) => onIrabSelect(value),
  })
  const ruleGroup = rulesRadioGroup.getRootProps();


  useEffect(() => {
    if (sentenceWord?.rule?.irab) {
      rulesRadioGroup.setValue(sentenceWord?.rule?.irab)
    } else {
      rulesRadioGroup.setValue("")
    }
  }, [sentenceWord])


  console.log("rulesRadioGroup", rulesRadioGroup)
  return (
      <Stack spacing={1}>
        <Heading as={"h3"} size={"md"}>I'rab</Heading>
        {/*<RadioGroup onChange={(value) => setSelectedIrab(value)} value={selectedIrab}>*/}
        {/*  {ruleNames.map(ruleName => <Radio value={ruleName} >{ruleName}</Radio>)}*/}
        {/*</RadioGroup>*/}
        <HStack
            {...ruleGroup}
            wrap={"wrap"} justify={"center"}
        >
          {ruleNames.map((value) => {
            const radio = rulesRadioGroup.getRadioProps({ value })
            // console.log("rulesRadioGroup", radio)
            return (
                <RadioCard
                    key={value}
                    {...radio}
                >
                  {value}
                </RadioCard>
            )
          })}
        </HStack>
      </Stack>
  )
}

function IrabReasonRadio(props : {sentenceWord: SentenceWord, irab: string, onReasonSelect: (rule: Rule) => void}) {
  const {sentenceWord, irab=null, onReasonSelect=()=>null} = {...props}

  const reasonsRadioGroup = useRadioGroup({
    name: "rules",
    onChange: (value) => onRuleRadioChange(value),
  })

  useEffect(() => {
    if (sentenceWord) {
      console.log("sentenceWord", sentenceWord)
      reasonsRadioGroup.setValue(sentenceWord?.rule?.name)
    } else {
      reasonsRadioGroup.setValue(null)
    }
  }, [sentenceWord])

  function onRuleRadioChange(ruleName: string) {
    if (ruleName) {
      onReasonSelect(rules[irab].find(rule => rule?.name === ruleName))
    }
  }

  return (
      <Stack spacing={1}>
        <Heading as={"h3"} size={"md"}>Reason</Heading>
        {irab && rules ?
            <HStack {...reasonsRadioGroup?.getRootProps()} wrap={"wrap"} justify={"center"}>
              {rules[irab]?.map((rule) => {
                const value = rule?.name
                const radio = reasonsRadioGroup?.getRadioProps({value})
                return (
                    <RadioCard
                        key={value}
                        {...radio}
                    >
                      {value}
                    </RadioCard>
                )
              })}
            </HStack>
            :
            <Text>Please select an I'rab first</Text>
        }
      </Stack>
  )
}

function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
      <Box as="label">
        <input {...input} />
        <Box
            {...checkbox}
            cursor="pointer"
            borderWidth="1px"
            borderRadius="md"
            boxShadow="md"
            width={"max-content"}
            marginTop={"5px"}
            _checked={{
              bg: "green.600",
              color: "white",
              borderColor: "green.600",
            }}
            _focus={{
              boxShadow: "outline",
            }}
            px={4}
            py={1}
            fontSize={"1em"}
        >
          {props.children}
        </Box>
      </Box>
  )
}


export default RulesPage;
