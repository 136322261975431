import {SentenceWord} from './models';
import {Stack, Tag} from '@chakra-ui/react';
import * as React from 'react';

export function WordTag(props: {sentenceWord: SentenceWord, selected?: boolean, onClick?: (sentenceWord: SentenceWord) => void}) {
  const {sentenceWord, selected=false, onClick=() => null} = {...props};
  const irab = sentenceWord?.rule?.irab || "";
  const irab_char = sentenceWord?.rule?.irab_char || "";
  let borderColor = "gray.500";
  let color = selected ? "white" : "black";
  let bgColor = selected ? "white" : "";

  if (irab === "marfoo") {
    borderColor = "blue.300"
    // color = "white"
  } else if (irab === "mansoob") {
    borderColor = "teal.300"
    // color = "white"
  } else if (irab === "majroor") {
    borderColor = "yellow.300"
    // color = "white"
  }

  return (
      <Stack spacing={1} align={"center"}>
        <Tag
            size={"lg"}
            colorScheme={"gray"}
            bg={selected ? "gray.100" : "gray.600"}
            color={selected ? "gray.900" : 'white'}
            borderColor={borderColor}
            borderWidth={"1px"}
            borderStyle={"solid"}
            fontSize={"1em"}
            py={2}
            onClick={() => onClick(sentenceWord)}
        >
          {`${sentenceWord.word + irab_char}`}
        </Tag>
        {sentenceWord?.rule?.name &&
        <Stack spacing={1}>
          <Tag>{sentenceWord?.rule?.name}</Tag>
        </Stack>
        }
      </Stack>
  )
}
